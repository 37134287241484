import "lazysizes"
import "picturefill"

$('a[href^="#"]').click(function () {
  const speed = 500;
  const href = $(this).attr("href");
  const target = $(href == "#" || href == "" ? 'html' : href);
  const position = target.offset().top - 80;
  $("html, body").animate({ scrollTop: position }, speed, "swing");
  return false;
});

//アコーディオン
$('.js-menu__item__link').each(function () {
  $(this).on('click', function () {
    $("+.system__box,+.system__box--s,+.que__box", this).slideToggle();
    $(this).toggleClass("is-active")
    return false;
  });
});

//ヘッダーメニュー
$('.header__spBtn').on('click', function () {
  $('html').toggleClass('scroll-prevent')
  if ($(this).hasClass('is-active')) {
    $(this).removeClass('is-active');
    $('.header__spMenu').slideUp();
  } else {
    $(this).addClass('is-active');
    $('.header__spMenu').slideDown();
  }
})


// チュートリアル ステップ切り替え
$(document).on('click', '[data-tab-progress]', function (e) {
  $(".progress__tag__item").removeClass('is-active');
  $(".progress__box").removeClass('is-active');
  $(this).addClass('is-active');
  const tutorialItem = $(this).data("tab-progress");
  $(`.progress__box[data-progress="${tutorialItem}"]`).addClass('is-active');
});

$('.topMv').slick({
  dots: false,
  arrows: false,
  slidesToShow: 1,
  fade: true,
  autoplay: true,
});